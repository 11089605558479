<template>
  <div class="grid">
    <div v-if="$slots.header" class="grid-header">
      <slot name="header"/>
    </div>

    <div class="grid-body">
      <slot/>
    </div>

    <div v-if="$slots.footer" class="grid-footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Grid',
  created () {
    this.$emit('grid:created')
  },
  mounted () {
    this.$nextTick(() => {
      this.$emit('grid:mounted')
    })
  },
  beforeDestroy () {
    this.$emit('grid:beforeDestroy')
  }
}
</script>

<style lang="scss">
$grid-margin: 0 0 $component-gap !default;

$grid-header-gap: $component-header-gap !default;
$grid-footer-gap: $component-footer-gap !default;

.grid {
  margin: $grid-margin;

  .grid-header {
    margin-bottom: $grid-header-gap;
    text-align: right;
  }

  .grid-body {}

  .grid-footer {
    margin-top: $grid-footer-gap;
    text-align: right;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
